//====================================================
//  Initialization Calendar
//====================================================
function fn_components_calendar(){
	if ($('#calendar').length){
		var data = [
	        {
	            date: '2016-10-9',
	            value: '<a href="#"><span class="text">21:00 Představení</span></a>'
	        },
	        {
	            date: '2016-10-20',
	            value: '<a href="#"><span class="text">6:00 Stretnutie</span></a>'
	        },
	        {
	            date: '2016-10-10',
	            value: '<a href="#"><span class="text">6:00 Stretnutie s ministrom vnútra na univerzite mateja béla</span></a>'
	        }
	    ];

	    var calendar = $('#calendar').calendar({
	        width: $('.calendar-block').width(),
	        weekArray: ['ne', 'Po', 'út', 'St', 'Čt', 'pá', 'so'],
	        startWeek: 1,
	        format: 'mm',
	        data: data
	        //view: 'month',
	        //height: 300,
	        // // selectedRang: [new Date(), null],
	        // onSelected: function (view, date, data) {
	        //     console.log('view:' + view)
	        //     console.log('date:' + date)
	        //     console.log('data:' + (data || '无'));
	        // },
	        // viewChange: function (view, y, m) {
	        //     console.log(view, y, m)
	        // }
	    });
	}
}