//====================================================
//  Reposition menu
//====================================================


function fn_components_repositionMenu(){
	if ($('.navigation .logo').length){
		var navigationWrapper = $('.navigation-wrapper');
		var navBlock = $('.nav-block');
		var infoList = $('.info-list');
		var imgSrc = window.location.protocol + "//" + window.location.host + '/';
		if ($(window).width() >= 1600){
			$('.navigation .logo img').attr('src', imgSrc + 'public/img/rv-med-logo-inverse.svg');
			$('.navigation .info-navigation .header-top').append(navBlock);
			$('.navigation .info-navigation').append(navigationWrapper);
			$('.navigation .info-block').prepend(infoList);
		} else if ($(window).width() > 992 && $(window).width() < 1600){
			$('.navigation .logo img').attr('src', imgSrc + 'public/img/rv-med-logo.svg');
			$('.navigation .nav-wrap').append(navigationWrapper);
			$('.navigation .info-navigation .header-top').append(navBlock);
			$('.navigation .info-block').prepend(infoList);
		} else if ($(window).width() < 992){
			$('.navigation .logo img').attr('src', imgSrc + 'public/img/rv-med-logo.svg');
			$('.navigation .nav-wrap').append(navigationWrapper);
			$('.navigation .nav-wrap .nav').append(navBlock);
			$('.navigation .nav-wrap .nav').append(infoList);
		}

	}
}