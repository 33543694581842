//====================================================
//  Initialization subnavigation
//====================================================
function fn_components_subnavigation(){
    if ($('.sub-navigation').length){
        $(".navigation-wrapper .nav-item, .sub-navigation").hover(function () {
            if ($(window).width() > 992){
                var inst = $(this);
                var thisId = inst.attr('id');
                var thisTabs = inst.closest('.navigation');

                if (thisId == undefined){
                    return false;
                }

                if (!inst.hasClass('active')) {
                    $('.navigation-wrapper .nav-item.active').removeClass('active');
                    
                    $(this).addClass('active');
                    
                    $('.secondary-navigation').addClass('active').find('.item.active').removeClass('active');

                    $('.sub-navigation .item[data-id="' + thisId + '"]').addClass('active');
                }
            }
        }, function() {
            if ($(window).width() > 992){
                if ($(this).hasClass('nav-item')){
                    return false;
                } 
                fn_components_disableSubmenu();
            }
        });
    
    }
}

function fn_components_subnavigation2(){
    if ($('.sub-navigation').length){
        $(".navigation-wrapper .nav-item").click(function () {
            if ($(window).width() > 992){
                var inst = $(this);
                var thisId = inst.attr('id');
                var thisTabs = inst.closest('.navigation');

                if (thisId == undefined){
                    return false;
                }

                if (!inst.hasClass('active')) {
                    $('.navigation-wrapper .nav-item.active').removeClass('active');
                    
                    $(this).addClass('active');
                    
                    $('#header').addClass('sub-active').find('.sub-navigation .item.active').removeClass('active');

                    $('.sub-navigation .item[data-id="' + thisId + '"]').addClass('active');
                    //fn_components_enableGlassEffect();
                } else {
                    fn_components_disableSubmenu();
                    //fn_components_enableGlassEffect();
                }
            }
        });
    
    }
}

// $(window).click(function() {
//     if ($('.navigation.sub-active').length){
//         fn_components_disableSubmenu();    
//     }
// });

$('html').click(function (e) {
    if (e.target.className == 'nav-link' || e.target.className == 'nav-item') {
    } else {
        fn_components_disableSubmenu(); 
    }
});


function fn_components_disableSubmenu(){
    $('.navigation-wrapper .nav-item.active').removeClass('active');
    $('#header').removeClass('sub-active');
    $('.sub-navigation .item.active').removeClass('active');
}
