/* ==========================================================================
 *  Full height
 * ======================================================================= */
function fn_parts_full_height(){
	if ($('#full-height').length){
       (function($){
	    	if($(window).width() > 560){
	        	//("#main_header").height( (($(window).height()) * 80) / 100 );
	        	$("#full-height").height( $(window).height() );
	    	} else {
	        	$("#full-height").css('height', '640');
	    	}
	    })(jQuery);
	}
}