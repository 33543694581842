/* ==========================================================================
 *  Quantity input
 * ======================================================================= */
function fn_parts_quantity_input(){
	if ($('.quantity').length){
       $('<div class="quantity-nav"><div class="quantity-button quantity-up"><span class="text">+</span></div><div class="quantity-button quantity-down"><span class="text">-</span></div></div>').insertAfter('.quantity input');
	    $('.quantity').each(function () {
	        var spinner = $(this),
	            input = spinner.find('input[type="number"]'),
	            btnUp = spinner.find('.quantity-up'),
	            btnDown = spinner.find('.quantity-down'),
	            min = input.attr('min'),
	            max = input.attr('max');

	        btnUp.click(function () {
	            var oldValue = parseFloat(input.val());
	            if (oldValue >= max) {
	                var newVal = oldValue;
	            } else {
	                var newVal = oldValue + 1;
	            }
	            spinner.find("input").val(newVal);
	            spinner.find("input").trigger("change");
	        });

	        btnDown.click(function () {
	            var oldValue = parseFloat(input.val());
	            if (oldValue <= min) {
	                var newVal = oldValue;
	            } else {
	                var newVal = oldValue - 1;
	            }
	            spinner.find("input").val(newVal);
	            spinner.find("input").trigger("change");
	        });


	    });
	}
}