/* ==========================================================================
 *  Main Section 100% height
 * ======================================================================= */


function fn_parts_navigation_scroll_resize(){
    if ($('.navigation').length){
        if ($('body').scrollTop() > 1 && $(window).width() < 992) {
            $('.navigation').addClass("navigation-scroll");
        } else {
            $('.navigation').removeClass("navigation-scroll");
        }
    }
}

