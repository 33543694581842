//====================================================
//  Initialization Slider
//====================================================
function fn_components_slider(){
    fn_detail_slider();
}

function fn_detail_slider() {
    if ($('.detail-slider-title').length) {
        $('.detail-slider-title').slick({
            infinite: true,
            arrows: true,
            asNavFor: '.detail-slider',
            autoplay: false,
            autoplaySpeed: 6000,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            mobileFirst: true,
            nextArrow: '<span class="slick-arrows slick-next"><i class="fa fa-chevron-right"></i></span>',
            prevArrow: '<span class="slick-arrows slick-prev"><i class="fa fa-chevron-left"></i></span>'
        });
    }
    if ($('.detail-slider').length) {
        $('.detail-slider').slick({
            infinite: true,
            arrows: false,
            asNavFor: '.detail-slider-title',
            autoplay: false,
            autoplaySpeed: 6000,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            mobileFirst: true
        });
    }
}