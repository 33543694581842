//====================================================
//  Glass effect
//====================================================

var blurOverlay;

function fn_components_glassEffect(){
	if ($(window).width() > 992 && $(window).width() < 1600){
		var height = "200px";
	} else {
		var height= "140px";
	}
	if ($(window).width() < 992){
		return false;
	}
	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
	    $('#header').addClass('ie-navigation');
	    return false;
	}
	if ($('#header').length){
		blurOverlay = $("#header").bluroverlay({
			contentWrapperClass: "page-content",
			blur: 10,
			opacity: 0.6,
			background: "rgba(60, 66, 80, 0.8)",
			height: height
		});
	}
}

function fn_components_glassEffectReInit(){
	if ($('.content-blurred').length){
		$('#header').attr('style', '');
		$('main.page-content').attr('style', '');
		$('.content-blurred').remove();
		$('#svg-image-blur').remove();
	}
	fn_components_glassEffect();
}


function fn_components_disableGlassEffect(){
	blurOverlay.hideBlurmask();
}

function fn_components_enableGlassEffect(){
	blurOverlay.showBlurmask();
}