//====================================================
//  Match height
//====================================================


function fn_components_matchHeight(){
	// if ($('.posts-panel').length) {
	// 	$('.posts-panel').matchHeight();
	// }
	if ($('.news-panel').length) {
		$('.news-panel').matchHeight();
	}
	if ($('.operations-panel').length) {
		$('.operations-panel').matchHeight();
	}
	if ($('.newsletter-panel').length) {
		$('.newsletter-panel').matchHeight();
	}
}
