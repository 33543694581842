// --- LAYOUT, COMPONENTS, PARTS --- //
$(function () {
	// ---  LAYOUT --- //
	//fn_layout_main();

	// ---  COMPONENTS --- //
	fn_components_fullscreen_navigation();
	fn_components_slider();
	fn_components_masonry();
	fn_components_ajaxLoad()
	fn_components_gallery()
	//fn_components_calendar();
	//fn_components_select();
	//fn_components_accordion();

	// ---  PARTS --- //
	//fn_parts_wow();
	//fn_parts_full_height();
	//fn_parts_quantity_input();
	//fn_parts_slide_down_up();
	//fn_parts_more_text();
	//fn_parts_navigation_scroll_resize();
	//fn_parts_is_in_viewport();
});


// --- MISC --- //
$(function () {
	nl_fn_scroll_to();
});

var resizeId, resizeGlass;
// --- EVENTS --- //
$(window).resize(function(){
});

$(document).scroll(function () {
    //fn_parts_navigation_scroll_resize();
});

$(window).load(function () {
	fn_parts_dots();
});