/* ==========================================================================
 *  Initialization WOW
 * ======================================================================= */
function fn_parts_wow(){
	wow = new WOW(
	    {
			boxClass:     'wow',      
			animateClass: 'animated', 
			offset:       0,          
			mobile:       true,       
			live:         true        
	    }
    )
    wow.init();
}