/* ==========================================================================
 *  Initialization Google maps
 * ======================================================================= */
function fn_components_google_map(){
	if ($('#map').length){
		var waypoint = new Waypoint({
			element: document.getElementById('map'),
			handler: function() {
			    var map;
		        var mapOptions = {
		            center: new google.maps.LatLng(49.590705,17.249995),
		            zoom: 17,
		            zoomControl: false,
		            disableDoubleClickZoom: true,
		            mapTypeControl: false,
		            scaleControl: false,
		            scrollwheel: false,
		            panControl: false,
		            streetViewControl: false,
		            draggable : true,
		            overviewMapControl: false,
		            overviewMapControlOptions: {
		                opened: false,
		            },
		            mapTypeId: google.maps.MapTypeId.ROADMAP,
		            styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":-100},{"gamma":0.5}]}],
		        }
		        var mapElement = document.getElementById('map');
		        var map = new google.maps.Map(mapElement, mapOptions);
		        var locations = [
					[ 49.5905507,  17.2495306, 'public/img/icons/map-marker.png']
		        ];
		        for (i = 0; i < locations.length; i++) {
		            marker = new google.maps.Marker({
		                icon: locations[i][2],
		                position: new google.maps.LatLng(locations[i][0], locations[i][1]),
		                map: map
		            });
				link = '';     
				}
			},
			offset: '100%'
		})
	}
}