/* ==========================================================================
 *  Ajax load more post to blog grid
 * ======================================================================= */


function fn_components_ajaxLoad(){


	if ($('.load-more-content').length){
		var counter = 0;
		$('.load-more-content').bind('click', function(){
			var content = $(this).attr('data-content');
			if (counter < 2){
				counter++;
			} else {
				return false;
			}


			$.ajax({
				url: 'public/js/data/' + content + '.html',
				data: 'html',
				beforeSend:function(){
					$('.vypis-list-section .load-more').addClass('disabled');
					$('.vypis-list-section .load-more .loading-post').addClass('active');
				},
				success:function(data){
					setTimeout(function(){ 
						//$('.vypis-list').append(data);
						var $content = $( data );
						$('.vypis-list').append( $content ).masonry( 'appended', $content );
						$('.vypis-list-section .load-more').removeClass('disabled');
						$('.vypis-list-section .load-more .loading-post').removeClass('active');

						if (counter === 2){
							$('.vypis-list-section .load-more').addClass('last');
						}
					}, 2000);
				},
				error:function(){
					console.log("error");
				}
			});

		});
	}
}
