//====================================================
//  Initialization MAsonry
//====================================================
function fn_components_masonry(){
	if ($('.sorting-block').length) {
		$(".sorting-block").masonry({
			itemSelector: ".item",
			//gutter: 20,
			percentPosition: true
		});
	}
}
