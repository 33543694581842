//====================================================
//  Initialization Tabs
//====================================================
function fn_components_tabs(){
	if ($('.tabs').length){
		$( ".tab .item span" ).click(function() {
	        var inst = $(this);
	        var thisId = inst.attr('id');
	        var thisTabs = inst.closest('.tab-wrapper');

	        if (!inst.hasClass('active')){
	            $( ".tab .item" ).each(function(){
	                $(this).closest('.item').removeClass('active');
	            });
	            $(this).closest('.item').addClass('active');
	            $(" .tab-wrapper .tabs-content .item" ).each(function(){
	                $(this).removeClass('active');
	            });
	            $('.tab-wrapper .tabs-content .item[data-id="'+ thisId + '"]').addClass('active');
	        }
	    });
	}
}