//====================================================
//  Initialization Navigation
//====================================================

function fn_components_fullscreen_navigation(){
	if ($('.js-side-panel-trigger').length){
		$('.js-side-panel-trigger').on('click', function(e){
	        e.preventDefault();
	        //$(this).closest('.side-panel').toggleClass('active');
	        $(this).closest('.side-panel').toggleClass('active');
	       	$('body').toggleClass('overflow-hidden');
	    });
	}
}
