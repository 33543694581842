/* ==========================================================================
 *  Viewport block visible
 * ======================================================================= */


function fn_parts_is_in_viewport(){
    if ($('.js-viewport').length){

	    var calendarWidth = $('.calendar-block').innerWidth();
    	$('.js-viewport').each(function(){
	        
	        var thisElement = $(this);
	        var eventPositionWidth = thisElement.innerWidth();
	        var datePosition = thisElement.closest('li').position();
	        var calculateReposition = datePosition.left + eventPositionWidth ;
	        if (calculateReposition > calendarWidth){
	        	thisElement.addClass('reposition');
	        } else {
	        	thisElement.removeClass('reposition');
	        }
		});
    }
}

