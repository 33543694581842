//====================================================
//  Scroll to 
//====================================================
function nl_fn_scroll_to(){
    if($('.scroll-trigger').length){
        $('.scroll-trigger').click(function () {
            thisItem = $(this)
            var target = $(this).attr('data-scroll');
            if (target.length) {
                $('html, body').stop().animate({
                    scrollTop: $('#' + target + '').offset().top - 60
                }), 2500 ;
                return false;
            }
        });
    }
}